<template>
  <div class="container">
    <!-- 轮播图 -->
    <div class="banner">
      <div class="content">
        <el-carousel trigger="click" height="800px">
          <el-carousel-item v-for="(item, index) in banner_imgs" :key="index">
            <a :href="item.link" target="_blank">
              <img v-lazy="net + item.pic" alt="" />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="download-section">
      <div class="content">
        <div class="title">
          <h4>APP下载</h4>
          <div class="line"></div>
        </div>
        <div class="download-link">
          <div class="link-qr">
            <img :src="net + service.app_download" alt="" />
            <p>请用浏览器扫码下载</p>
          </div>
          <div class="link-app">
            <a :href="ios" target="_blank" class="ios">
              <img src="../assets/img/apple.png" alt="" />
              <div class="app-desc">
                <h4>APP Store Available</h4>
                <span>点击下载</span>
              </div>
            </a>
            <a :href="android" target="_blank" class="android">
              <img src="../assets/img/android.png" alt="" />
              <div class="app-desc">
                <h4>Android APP</h4>
                <span>点击下载</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { getHome, getDownload } from "@network/home";
export default {
  name: "Download",
  data() {
    return {
      net: BASE_URL,
      downloadData: {
        url: window.location.href,
      },
      banner_imgs: [],
      service: {},
      android: "",
      ios: "",
    };
  },
  created() {
    this.getHome();
    this.getDownload();
  },

  components: {},

  methods: {
    bannerTo(type) {
      if (type == 0) {
        return false;
      } else if (type == 1) {
        this.$router.push("/joinRaise");
      } else if (type == 2) {
        this.$router.push("/customized");
      }
    },
    getHome() {
      getHome().then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$message.error({
              message: "请重新登录",
              offset: 300,
            });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取失败",
            offset: 300,
          });
          return false;
        } else {
          this.service = res.data.homePage;
          this.banner_imgs = res.data.carousel;
        }
      });
    },
    getDownload() {
      getDownload().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        }
        this.android = res.data.android_download;
        this.ios = res.data.apple_download;
      });
    },
  },

  computed: {},
};
</script>
<style lang='less' scoped>
@import "../assets/css/download.less";
.link-qr {
  img {
    width: 196px;
    height: 196px;
  }
  p{
    font-size: 14px;
    color: red;
    text-align: center;
  }
}
</style>